'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _reduxBatch = require('./reduxBatch');

Object.defineProperty(exports, 'reduxBatch', {
  enumerable: true,
  get: function get() {
    return _reduxBatch.reduxBatch;
  }
});